import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createCustomer } from "../../api_url/customerTable";
import { useAuth } from "../../context/AuthContext";
import { formatPhoneNumber } from "../../utils/function";

function Table({
  columns,
  datas,
  setColumns,
  setJsonData,
  setFile,
  orgLocation,
  orgLocationIdValue,
  orgLocationIdData
}) {
  const { authToken } = useAuth();

  // console.log(datas);
  const uniqueCSVFileArr = datas?.filter((item, index, array) => {
    return (
      array?.findIndex((element) => {
        return (
          element.firstName === item.firstName ||
          element.lastName === item.lastName ||
          element.phoneOne === item.patientOne
        );
      }) === index
    );
  });
  // console.log(uniqueCSVFileArr);
  // Find items in CSVFileArr that are not present in ServerArr
  const data = uniqueCSVFileArr?.filter((item) => {
    return !orgLocationIdData?.some((serverItem) => {
      return (
        serverItem.firstName === item.firstName ||
        serverItem.lastName === item.lastName ||
        serverItem.phoneOne === item.patientOne
      );
    });
  });
  // console.log(data);

  // The 'notMatchedItems' array contains the items in CSVFileArr that are not present in ServerArr

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [rowLength, setRowLength] = useState(0);
  const [invalidRowss, setInvalidRowss] = useState(undefined);

  const [editedData, setEditedData] = useState(() => {
    // create missing fields with empty string
    return data.map((item) =>
      columns.reduce((acc, curr) => {
        const accessor = curr.accessor.replace(/\s+/g, "_").toLowerCase();
        acc[accessor] = item[curr.accessor] || "";
        return acc;
      }, {})
    );
  });

  const [columnsData, setColumnsData] = useState(() => {
    return columns.map((column) => {
      return {
        Header: column.Header,
        accessor: column.accessor.toLowerCase().replace(/ /g, "_")
      };
    });
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newData = [...editedData];
    newData[index][name] = value;
    setEditedData(newData);
  };

  const validateFields = () => {
    let isValid = true;
    const newData = [...editedData];
    const invalidRows = [];
    const emptyFields = [];

    for (let i = 0; i < newData.length; i++) {
      const data = newData[i];
      const firstName = data?.firstName?.toString().trim();
      const lastName = data?.lastName?.toString().trim();
      const address = data?.fullAddress?.toString().trim();
      const city = data?.city?.toString().trim();
      const state = data?.state?.toString().trim();
      const zip = data?.zipCode?.toString().trim();
      const gender = data?.gender?.toString().trim();
      const phone = data?.phoneOne?.toString().trim();

      if (
        firstName === "" ||
        lastName === "" ||
        address === "" ||
        city === "" ||
        state === "" ||
        zip === "" ||
        gender === "" ||
        phone === ""
      ) {
        isValid = false;
        invalidRows.push(data);
      }
    }

    for (let i = 0; i < newData.length; i++) {
      const firstName = newData[i]["firstName"];
      const lastName = newData[i]["lastName"];
      const address = newData[i]["fullAddress"];
      const city = newData[i]["city"];
      const state = newData[i]["state"];
      const zip = newData[i]["zipCode"];
      const gender = newData[i]["gender"];
      const phone = newData[i]["phoneOne"];
      if (
        firstName === "" ||
        lastName === "" ||
        address === "" ||
        city === "" ||
        state === "" ||
        zip === "" ||
        gender === "" ||
        phone === ""
      ) {
        isValid = false;
        emptyFields.push(
          `firstName_${i}`,
          `lastName_${i}`,
          `fullAddress_${i}`,
          `city_${i}`,
          `state_${i}`,
          `zipCode_${i}`,
          `gender_${i}`,
          `phoneOne_${i}`
        );
      }
    }

    setEmptyFields(emptyFields);

    return { isValid, invalidRows };
  };

  const postCustomer = async (
    firstName,
    lastName,
    fullAddress,
    latitude,
    longitude,
    city,
    state,
    zipCode,
    gender,
    phoneOne,
    phoneTwo,
    email
  ) => {
    try {
      const item = await createCustomer(authToken, {
        firstName: firstName,
        lastName: lastName,
        fullAddress: fullAddress,
        latitude: latitude,
        longitude: longitude,
        cityId: "",
        city: city,
        state: state,
        zipCode: zipCode,
        gender: gender,
        phoneOne: phoneOne,
        phoneTwo: phoneTwo,
        email: email,
        active: true,
        organization: orgLocation,
        location_id: orgLocationIdValue
      });

      return item;
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }

      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const getAddress = async (data) => {
    // console.log(data)
    let address = data;

    const GOOGLE_PLACES_API_KEY = "AIzaSyASXZSrsvL25WiY7nZc2M4WJhkDMj-jkvs";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${GOOGLE_PLACES_API_KEY}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      let arr = [];
      data.results[0].address_components?.map((i) => arr.push(i?.long_name));
      let full_address = arr?.join(",");
      let obj = {
        fullAddress: full_address,
        latitude: data.results[0].geometry.location.lat,
        longitude: data.results[0].geometry.location.lng
      };

      return obj;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const handleUpload = async () => {
    const { isValid, invalidRows } = validateFields();

    const validRows = editedData.filter((data) => {
      // Check if the row object is not present in the invalidRows array
      return !invalidRows.some((invalidRow) => deepEqual(invalidRow, data));
    });

    // console.log("valid rows:", validRows);
    // console.log("isValid:", isValid);
    // console.log("Invalid rows:", invalidRows);
    // console.log("emptyFields:", emptyFields);

    if (validRows?.length > 0) {
      setLoading(true);

      const postCustomerPromise = (data) => {
        return new Promise(async (resolve, reject) => {
          // console.log(data)

          let address = await getAddress(
            data?.fulladdress?.toString() +
              "," +
              data?.city?.toString() +
              "," +
              data?.state?.toString() +
              "," +
              data?.zipcode?.toString()
          );
          postCustomer(
            data?.firstname?.toString(),
            data?.lastname?.toString(),
            address?.fullAddress?.toString(),
            address?.latitude?.toString(),
            address?.longitude?.toString(),
            data?.city?.toString(),
            data?.state?.toString(),
            data?.zipcode?.toString(),
            data?.gender?.toString(),
            formatPhoneNumber(data?.phoneone?.toString()),
            formatPhoneNumber(data?.phonetwo?.toString()),
            data?.email?.toString()
          )
            .then((res) => {
              console.log(res);
              resolve(res); // Resolve the promise with the response
            })
            .catch((error) => {
              console.error(error);
              reject(error); // Reject the promise with the error
            });
        });
      };

      setRowLength(validRows?.length);
      // console.log(validRows?.length);
      Promise.all(validRows.map(postCustomerPromise))
        .then((responses) => {
          setLoading(false);
          setInvalidRowss(invalidRows);
          setAlert(true);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const successOk = () => {
    if (invalidRowss.length > 0) {
      setError(true);
      setEditedData(invalidRowss);
      setAlert(false);
    } else {
      setError(false);
      setColumns([]);
      setJsonData([]);
      setEditedData([]);
      setFile("");
      window.location.reload();
      setAlert(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = editedData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(editedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div style={{ overflowX: "auto" }} className="mx-5">
        {error && (
          <p className="mb-4 text-red-500">
            Please review the record, fill in any missing fields and then
            upload.
          </p>
        )}
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "lightgray" }} className="uppercase">
            <tr>
              {columnsData.map((column) => (
                <th
                  key={column.accessor}
                  style={{ border: "1px solid black", padding: "8px" }}
                >
                  {column.Header === "First Name"
                    ? "First Name*"
                    : column.Header === "Last Name"
                    ? "Last Name*"
                    : column.Header === "Address Line 1"
                    ? "Address Line 1*"
                    : column.Header === "City"
                    ? "City*"
                    : column.Header === "State"
                    ? "State*"
                    : column.Header === "Zip Code"
                    ? "Zip Code*"
                    : column.Header === "Gender"
                    ? "Gender*"
                    : column.Header === "Phone"
                    ? "Phone*"
                    : column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                {columnsData.map((column) => (
                  <td
                    key={column.accessor}
                    style={{ border: "1px solid black", padding: "8px" }}
                  >
                    <input
                      type="text"
                      name={column.accessor}
                      value={item[column.accessor] || ""}
                      onChange={(event) => handleInputChange(event, index)}
                      style={
                        emptyFields.includes(`${column.accessor}_${index}`)
                          ? { border: "1px solid red", padding: "8px" }
                          : { border: "1px solid black", padding: "8px" }
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="space-x-3 mr-5 flex justify-end mt-5 items-center">
        <div>
          <label>Items per page:</label>
          <select
            value={itemsPerPage}
            onChange={(event) => setItemsPerPage(event.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <button
          onClick={() => handlePrevPage()}
          className={`px-5 py-1 rounded-md ${
            currentPage > 1
              ? "bg-blue-500 text-white"
              : "bg-slate-100 text-black cursor-not-allowed"
          }`}
        >
          pre
        </button>
        <p>{currentPage}</p>
        <button
          onClick={() => handleNextPage()}
          className={`px-5 py-1 rounded-md ${
            currentPage < pageNumbers.length
              ? "bg-blue-500 text-white"
              : "bg-slate-100 text-black cursor-not-allowed"
          }`}
        >
          next
        </button>
      </div>

      {currentItems?.length > 0 && (
        <div className="flex justify-end mt-5 mr-5 items-center">
          <button
            className="bg-green-600 my-5 text-white px-5 py-1 rounded-md"
            onClick={handleUpload}
          >
            {loading ? "Loading..." : "Upload"}
          </button>
        </div>
      )}
      <Transition.Root show={alert} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(e) => setAlert(e)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Success
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {`${rowLength} ${
                              rowLength > 1 ? "records" : "record"
                            } updated successfully.`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center 
                      rounded-md bg-green-600 px-6 py-2 text-sm font-semibold 
                      text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() => successOk()}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Table;
