import React, { useState } from 'react'
import NurseTableView from './NurseTableView'
import FacilityTableView from './FacilityTableView'

function UserView() {

  const [tab, setTab] = useState("Nurse List")

  return (
    <div>
      <div className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        <div
          className={`
      w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
      ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
      ${tab === "Nurse List"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
      `}
          onClick={() => { setTab("Nurse List") }}
        >
          Employee List
        </div>
        <div
          className={`
      w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
      ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
      ${tab === "Manager List"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
      `}
          onClick={() => { setTab("Manager List") }}
        >
          Manager List
        </div>
      </div>
      {
        tab === "Nurse List" ?
          <NurseTableView />
          :
          <FacilityTableView />
      }
    </div>
  )
}

export default UserView