import { API_URL } from "./config";

export async function getFacilityDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getFacilityUniqueIdDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/uniqueId/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getFacilityByEmail(authToken, email) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/by/email/${email}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function updateDetails(authToken, id, data) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}

export async function createFacility(authToken, data) {
  if (!authToken) {
    return;
  }
  console.log(JSON.stringify(data))
  const res = await fetch(`${API_URL}/facility`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }
  if (res.status === 401) {
    throw new Error("Email Id already exist!");
  }

  return await res.json();
}

export async function getAllFacilityList(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/all/by/location/list/data/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getCalenderDataFacility(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/my/calender/data/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAgendaDataFacility(authToken, id, date) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility/my/calender/${date}/details/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}