import React, { Fragment, useEffect, useState } from "react";
import { MdEdit, MdOutlineClose, MdDelete } from "react-icons/md";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useHistory } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Dialog, Transition } from "@headlessui/react";
import JobForm from "./JobForm";
import { getCustomerTableList } from "../../api_url/customerTable";
import { useAuth } from "../../context/AuthContext";
import {
  getAllFacilityOrgList,
  getAllNurseOrgList,
  getOrganizationDetails
} from "../../api_url/organizationTable";
import {
  getJobStatusListLocation,
  postJobTable,
  updateJobTable,
  deleteJob
} from "../../api_url/jobTable";
import { sendNotification } from "../../api_url/webNotification";
import List from "../../components/ListLocation";

function JobTableView({ type }) {
  const history = useHistory();
  const { authToken, userId } = useAuth();

  const [open, setOpen] = useState({
    condition: false,
    item: {}
  });

  const [deleteAll, setDeleteAll] = useState(false);
  const [addRecord, setAddRecord] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [prePage, setPrePage] = useState(null);
  const [orgLocation, setOrgLocation] = useState("");
  const [orgLocationId, setOrgLocationId] = useState([]);
  const [orgLocationIdView, setOrgLocationIdView] = useState(false);
  const [orgLocationIdValue, setOrgLocationIdValue] = useState("");
  const [orgLocationIdValueList, setOrgLocationIdValueList] = useState({
    name: "Select location Id"
  });

  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [edit, setEdit] = useState(undefined);
  const [editedData, setEditedData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [jobColumnsData, setJobColumnsData] = useState([
    {
      Header: "job_Status",
      accessor: "jobStatus"
    },
    {
      Header: "shift_Title",
      accessor: "shiftTitle"
    },
    {
      Header: "start_Date",
      accessor: "startDate"
    },
    {
      Header: "start_Time",
      accessor: "startTime"
    },
    {
      Header: "end_Date",
      accessor: "endDate"
    },
    {
      Header: "end_Time",
      accessor: "endTime"
    },
    {
      Header: "break",
      accessor: "break"
    },
    {
      Header: "customer_name",
      accessor: "customerName"
    },
    {
      Header: "job_Type",
      accessor: "jobType"
    },
    {
      Header: "full_Address",
      accessor: "fullAddress"
    },
    {
      Header: "created",
      accessor: "createdAt"
    }
  ]);

  const [licenseTypeArr, setLicenseTypeArr] = useState([]);

  const [customerList, setCustomerList] = useState(undefined);
  const [managerList, setManagerList] = useState(undefined);
  const [nurseList, setNurseList] = useState(undefined);

  const [jobNewData, setJobNewData] = useState({
    shiftTitle: "", //
    startDate: new Date(), //
    endDate: new Date(), //
    startTime: new Date(), //
    endTime: new Date(), //
    break: "No Break", //
    customerName: "Select Customer", //
    customerId: "", //
    unit: "", //
    floor: "", //
    fullAddress: "", //
    latitude: "", //
    longitude: "", //
    notes: "", //
    jobType: type, //
    jobTiming: "", //function
    customerVisibility: false, //
    jobPostingTableFacilityTableId: "",
    jobPostingTableFacilityTableName: ""
  });

  const getOrgLocationList = async () => {
    await getOrganizationDetails(authToken, userId)
      .then((res) => {
        let arr = [];
        // console.log(res)
        if (res) {
          setOrgLocation(res.organizationName);
          res?.locations.map((i) =>
            arr.push({
              name: i.location_id
            })
          );
          if (res?.locations.length === arr.length) {
            // console.log(arr);
            setOrgLocationId(arr);
            setLoadingScreen(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLocationJobList = async (
    location,
    organization,
    type,
    page,
    itemsPerPage
  ) => {
    setLoading(true);
    setEditedData([]);
    setDownloadData([]);
    try {
      const data = await getJobStatusListLocation(
        authToken,
        location,
        organization,
        type,
        "Open",
        page,
        itemsPerPage
      );

      // console.log(data)
      setNextPage(data?.nextPage);
      setPrePage(data?.previousPage);
      const cpData = await getCustomerTableList(
        authToken,
        location,
        organization
      );
      setCustomerList(cpData);

      const managerData = await getAllFacilityOrgList(
        authToken,
        location,
        organization
      );
      const nurseData = await getAllNurseOrgList(
        authToken,
        location,
        organization
      );

      setNurseList(nurseData);

      setManagerList(managerData);

      // const columns = Object.keys(list[0]).map((key) => {
      //   return { Header: key, accessor: key };
      // });
      // setColumnsData(() => {
      //   return columns.map((column) => {
      //     return {
      //       Header: column.Header,
      //       accessor: column.accessor.toLowerCase().replace(/ /g, "_"),
      //     };
      //   });
      // });

      let array = [];

      for (let i = 0; i < data?.data?.length; i++) {
        const item = data?.data[i];
        let obj = {
          LicenseType: item.licenseType,
          Title: item.shiftTitle,
          JobType: item.jobType,
          StartDate: moment(item.startDate).format("MM-DD-YYYY"),
          EndDate: moment(item.endDate).format("MM-DD-YYYY"),
          StartTime: moment(item.startTime).format("hh:mm a"),
          EndTime: moment(item.endTime).format("hh:mm a"),
          Break: item.break,
          CustomerName: item.customerName,
          Unit: item.unit,
          Floor: item.floor,
          FullAddress: item.fullAddress,
          Notes: item.notes,
          CustomerVisibility: item.customerVisibility ? "Yes" : "No"
        };
        array.push(obj);
      }

      // Assuming the array of objects is stored in a variable called 'data'
      // const duplicates = list.filter((item, index, a) => {
      //   return a.some((element, i) => {
      //     // Avoid comparing the same element or elements after the current index
      //     if (i <= index) return false;

      //     // Compare the desired fields for duplication
      //     return (
      //       element.patient_first_name === item.patient_first_name &&
      //       element.patient_last_name === item.patient_last_name &&
      //       element.patient_address_line_1 === item.patient_address_line_1 &&
      //       element.patient_city === item.patient_city &&
      //       element.patient_state === item.patient_state &&
      //       element.patient_zip_code === item.patient_zip_code
      //     );
      //   });
      // });

      // console.log(duplicates);
      // console.log(data?.data?.length, array?.length)
      if (data?.data?.length === array.length) {
        setEditedData(data?.data);
        setDownloadData(array);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setEditedData([]);
      setDownloadData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrgLocationList();
  }, [authToken, userId]);

  useEffect(() => {
    if (orgLocationIdValue !== "" && orgLocation !== "") {
      getLocationJobList(
        orgLocationIdValue,
        orgLocation,
        type,
        1,
        itemsPerPage
      );
    }
  }, [orgLocationIdValue, itemsPerPage, type]);

  const handleInputChangeNewData = (event) => {
    setJobNewData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const validateFields = (data) => {
    let isValid = true;
    const emptyFields = [];

    const shiftTitle = data?.shiftTitle;
    const jobBreak = data?.break;
    const fullAddress = data?.fullAddress;
    const jobPostingTableFacilityTableId = data?.jobPostingTableFacilityTableId;

    if (
      shiftTitle === "" ||
      jobBreak === "" ||
      jobPostingTableFacilityTableId === "" ||
      fullAddress === "" ||
      licenseTypeArr.length === 0
    ) {
      isValid = false;

      if (jobPostingTableFacilityTableId === "") {
        emptyFields.push("Select a manager");
      }
      if (licenseTypeArr.length === 0) {
        emptyFields.push("Select a license");
      }
      if (shiftTitle === "") {
        emptyFields.push("Title is missing");
      }
      if (jobBreak === "") {
        emptyFields.push("Break is missing");
      }
      if (fullAddress === "") {
        emptyFields.push("Address is missing");
      }
    }

    return {
      isValid,
      emptyFields
    };
  };

  // const dataCheck = !editedData.some((serverItem) => {
  //   return (
  //     serverItem.shiftTitle === jobNewData.shiftTitle
  //   );
  // });

  // const dataCheckEdit = !editedData.some((serverItem) => {
  //   return (
  //     serverItem.patient_first_name === edit?.patient_first_name &&
  //     serverItem.patient_last_name === edit?.patient_last_name &&
  //     serverItem.patient_address_line_1 === edit?.patient_address_line_1 &&
  //     serverItem.patient_city === edit?.patient_city &&
  //     serverItem.patient_state === edit?.patient_state &&
  //     serverItem.patient_zip_code === edit?.patient_zip_code
  //   );
  // });

  function GetDateMergedTime(date, time) {
    const date1 = moment(date).format("YYYY-MM-DD");
    const time1 = moment(time).format("hh:mm a");
    const mergedTime = moment(`${date1} ${time1}`, `YYYY-MM-DD hh:mm a`);
    return mergedTime;
  }

  function timeDifferent(sTime, eTime) {
    const startTime = moment(
      moment(new Date(sTime)).format("hh:mma"),
      "hh:mm A"
    );

    const start = moment(new Date(sTime));
    const end = moment(new Date(eTime));

    const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
    const endTimeEnd = moment(
      moment(new Date(eTime)).format("hh:mma"),
      "hh:mm A"
    ).add(1, "day");

    const duration = moment.duration(endTime.diff(startTime));
    const durationEnd = moment.duration(endTimeEnd.diff(startTime));

    const hours = Math.floor(duration.asHours());
    const hoursEnd = Math.floor(durationEnd.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;

    // const onlyHours = `${
    //   end.hour() <= 12 && start.hour() <= 12
    //     ? hours
    //     : end.hour() <= 12
    //     ? hoursEnd
    //     : hours
    // } ${
    //   end.hour() <= 12 && start.hour() <= 12
    //     ? hours <= 1
    //       ? "hour"
    //       : "hours"
    //     : end.hour() <= 12
    //     ? hoursEnd <= 1
    //       ? "hour"
    //       : "hours"
    //     : hours <= 1
    //     ? "hour"
    //     : "hours"
    // }`;

    const onlyHours = `${
      end.hour() <= 12 && start.hour() <= 12
        ? hours
        : end.hour() <= 12
        ? hoursEnd
        : hours
    }${hours <= 1 ? "hour" : "hours"} ${
      minutes <= 0 ? "" : `${minutes}minutes`
    }`;

    return onlyHours;
  }

  const postJob = async () => {
    const validationResult = validateFields(jobNewData);
    const ct = new Date(
      GetDateMergedTime(
        jobNewData.startDate,
        jobNewData.startTime.toString()
      ).toString()
    );
    const currentHour = ct.getHours();
    let jobTiming =
      currentHour >= 5 && currentHour < 12
        ? "Morning"
        : currentHour >= 12 && currentHour < 17
        ? "Afternoon"
        : currentHour >= 17 && currentHour < 20
        ? "Evening"
        : "Night";
    if (validationResult.isValid) {
      // if (dataCheck) {
      try {
        const item = await postJobTable(
          authToken,
          licenseTypeArr,
          jobNewData.shiftTitle,
          "",
          [],
          "",
          "0",
          moment(jobNewData.startDate).format("YYYY-MM-DD"),
          moment(jobNewData.endDate).format("YYYY-MM-DD"),
          GetDateMergedTime(
            jobNewData.startDate,
            jobNewData.startTime.toString()
          ).toString(),
          GetDateMergedTime(
            jobNewData.endDate,
            jobNewData.endTime.toString()
          ).toString(),
          jobNewData.break,
          jobNewData.customerName,
          jobNewData.customerId.toString(),
          jobNewData.unit,
          jobNewData.floor,
          jobNewData.fullAddress,
          jobNewData.latitude.toString(),
          jobNewData.longitude.toString(),
          "1",
          false,
          jobNewData.notes,
          type,
          "Open",
          "",
          "",
          [],
          false,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          timeDifferent(jobNewData?.startTime, jobNewData?.endTime),
          jobTiming,
          "",
          [],
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          false,
          false,
          false,
          false,
          false,
          jobNewData.customerVisibility,
          "",
          "",
          {},
          "",
          jobNewData.jobPostingTableFacilityTableId.toString(),
          false,
          "",
          "",
          "",
          false,
          false,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          GetDateMergedTime(
            jobNewData?.startDate,
            jobNewData.startTime.toString()
          )
            .valueOf()
            .toString(),
          "",
          "",
          "",
          "",
          orgLocation,
          orgLocationIdValue
        );
        alert(item?.shiftTitle + " created successfully.");
        setLicenseTypeArr([]);
        setJobNewData({
          shiftTitle: "",
          startDate: new Date(),
          endDate: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          break: "No Break",
          customerName: "Select Customer",
          customerId: "",
          unit: "",
          floor: "",
          fullAddress: "",
          latitude: "",
          longitude: "",
          notes: "",
          jobType: type,
          jobTiming: "",
          customerVisibility: false,
          jobPostingTableFacilityTableId: "",
          jobPostingTableFacilityTableName: ""
        });
        setAddRecord(false);
        getLocationJobList(
          orgLocationIdValue,
          orgLocation,
          type,
          1,
          itemsPerPage
        );
        nurseList?.map(async (item) => {
          if (item?.mobileId) {
            sendNotification(authToken, {
              expoPushToken: item?.mobileId,
              title: "New Job",
              body: `${type} available in your area.`,
              data: { screen: "HomeScreen" }
            });
          }
        });
      } catch (error) {
        console.log("data save error:", error);
      }
      // } else {
      //   alert(
      //     jobNewData.shiftTitle + " record already stored in database!"
      //   );
      // }
    } else {
      alert(
        validationResult.emptyFields +
          (validationResult.emptyFields.length > 1
            ? validationResult.emptyFields.length ===
              validationResult.emptyFields.length - 1
              ? ","
              : ""
            : "") +
          ". Please enter a value."
      );
    }
  };

  const clickEdit = (item) => {
    const originalDateStringST = moment(item?.startTime);
    const originalDateStringET = moment(item?.endTime);

    const originalDateStringSD = moment(
      GetDateMergedTime(
        moment(item?.startDate).format("MM-DD-YYYY"),
        originalDateStringST.toString()
      )
    );
    const originalDateSD = momentTimezone(originalDateStringSD);
    const cstDateSD = originalDateSD.tz("America/Chicago");
    const formattedDateSD = cstDateSD.format(`ddd MMM DD YYYY HH:mm:ss [GMT]Z`);

    const originalDateStringED = moment(
      GetDateMergedTime(
        moment(item?.endDate).format("MM-DD-YYYY"),
        originalDateStringET.toString()
      )
    );
    const originalDateED = momentTimezone(originalDateStringED);
    const cstDateED = originalDateED.tz("America/Chicago");
    const formattedDateED = cstDateED.format(`ddd MMM DD YYYY HH:mm:ss [GMT]Z`);

    setLicenseTypeArr(item?.licenseType);
    // console.log(managerList)
    let managerName = managerList?.filter(
      (i) => i?.id === Number(item?.jobPostingTableFacilityTableId)
    );
    // console.log(managerName)

    setJobNewData({
      ...jobNewData,
      shiftTitle: item?.shiftTitle,
      startDate: new Date(formattedDateSD),
      endDate: new Date(formattedDateED),
      startTime: new Date(formattedDateSD),
      endTime: new Date(formattedDateED),
      break: item?.break,
      customerName: item?.customerName,
      customerId: item?.customerId,
      unit: item?.unit,
      floor: item?.floor,
      fullAddress: item?.fullAddress,
      latitude: item?.latitude,
      longitude: item.longitude,
      notes: item?.jobTiming,
      jobType: item?.jobType,
      customerVisibility: item?.customerVisibility,
      jobPostingTableFacilityTableId: managerName[0]?.id,
      jobPostingTableFacilityTableName:
        managerName[0]?.firstName + " " + managerName[0]?.lastName
    });
    setAddRecord(true);
    setEdit(item);
  };
  // console.log(edit)
  const updateCustomer = async (item) => {
    const validationResult = validateFields(edit);

    const ct = new Date(jobNewData?.startDate.toString());
    const currentHour = ct.getHours();
    let jobTiming =
      currentHour >= 5 && currentHour < 12
        ? "Morning"
        : currentHour >= 12 && currentHour < 17
        ? "Afternoon"
        : currentHour >= 17 && currentHour < 20
        ? "Evening"
        : "Night";

    if (validationResult.isValid) {
      try {
        const res = await updateJobTable(authToken, edit?.id, {
          licenseType: licenseTypeArr,
          shiftTitle: jobNewData.shiftTitle,
          startDate: moment(jobNewData.startDate).format("YYYY-MM-DD"),
          endDate: moment(jobNewData.endDate).format("YYYY-MM-DD"),
          startTime: GetDateMergedTime(
            jobNewData.startDate,
            jobNewData.startTime.toString()
          ).toString(),
          endTime: GetDateMergedTime(
            jobNewData.endDate,
            jobNewData.endTime.toString()
          ).toString(),
          break: jobNewData.break,
          customerName: jobNewData.customerName,
          customerId: jobNewData.customerId.toString(),
          unit: jobNewData.unit,
          floor: jobNewData.floor,
          fullAddress: jobNewData.fullAddress,
          latitude: jobNewData.latitude.toString(),
          longitude: jobNewData.longitude.toString(),
          notes: jobNewData.notes,
          jobDuration: timeDifferent(
            jobNewData?.startTime,
            jobNewData?.endTime
          ),
          jobPostingTableFacilityTableId:
            jobNewData?.jobPostingTableFacilityTableId.toString(),
          jobType: jobNewData.jobType,
          jobTiming: jobTiming,
          customerVisibility: jobNewData.customerVisibility
        });
        alert(res.shiftTitle + " record updated successfully.");
        setAddRecord(false);
        setLicenseTypeArr([]);
        setJobNewData({
          shiftTitle: "",
          startDate: new Date(),
          endDate: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          break: "No Break",
          customerName: "Select Customer",
          customerId: "",
          unit: "",
          floor: "",
          fullAddress: "",
          latitude: "",
          longitude: "",
          notes: "",
          jobType: type,
          jobTiming: "",
          customerVisibility: false,
          jobPostingTableFacilityTableId: "",
          jobPostingTableFacilityTableName: ""
        });
        getLocationJobList(
          orgLocationIdValue,
          orgLocation,
          type,
          1,
          itemsPerPage
        );
      } catch (error) {
        console.log("data save error:", error);
      }
    } else {
      alert(
        validationResult.emptyFields +
          (validationResult.emptyFields.length > 1
            ? validationResult.emptyFields.length ===
              validationResult.emptyFields.length - 1
              ? ","
              : ""
            : "") +
          ". Please enter a value."
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(editedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (nextPage !== null) {
      getLocationJobList(
        orgLocationIdValue,
        orgLocation,
        type,
        nextPage,
        itemsPerPage
      );
    }
  };

  const handlePrevPage = () => {
    if (prePage !== null) {
      getLocationJobList(
        orgLocationIdValue,
        orgLocation,
        type,
        prePage,
        itemsPerPage
      );
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(editedData);

  const handleSearch = (value) => {
    setSearchTerm(value);

    const filteredData = editedData.filter((item) => {
      for (const key in item) {
        const value = item[key];
        if (value && value.toString().toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
      return false;
    });

    setSearchResults(filteredData);
  };

  const currentItems =
    searchTerm === ""
      ? editedData.slice(indexOfFirstItem, indexOfLastItem)
      : searchResults.slice(indexOfFirstItem, indexOfLastItem);

  function convertToCSV(jsonData) {
    const separator = ",";
    const keys = Object.keys(jsonData[0]);
    const csvContent = [
      keys.join(separator),
      ...jsonData.map((item) => {
        // Extract the 'FullAddress' and 'LicenseType' properties
        const { FullAddress, LicenseType, ...rest } = item;

        // Join the values of the other properties with the 'FullAddress' and 'LicenseType' values
        const values = keys.map((key) =>
          key === "FullAddress"
            ? `"${FullAddress}"`
            : key === "LicenseType"
            ? `"${LicenseType.join(", ")}"`
            : rest[key]
        );

        return values.join(separator);
      })
    ].join("\n");

    return csvContent;
  }

  const downloadCSVFile = (data) => {
    let current = moment().format("MM-DD-YYYY");
    // Create a Blob object for the CSV data
    const blob = new Blob([data], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `JobList-${type}-${current}.csv`;

    // Trigger the download
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  const deleteRecord = async (item) => {
    // console.log("Delete", item);
    await deleteJob(authToken, item?.id)
      .then((res) => {
        setSearchTerm("");
        setOpen({ condition: false, item: {} });
        getLocationJobList(
          orgLocationIdValue,
          orgLocation,
          type,
          1,
          itemsPerPage
        );
      })
      .catch((e) => console.log(e));
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
  };

  const closeSelect = () => {
    setDeleteShow(false);
    setSelectedItems([]);
  };

  const deleteMultipleRecord = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      console.log(selectedItems[i]);
    }
    getLocationJobList(orgLocationIdValue, orgLocation, type, 1, itemsPerPage);
    setSelectedItems([]);
    setDeleteShow(false);
    setDeleteAll(false);
  };

  // console.log(selectedItems);

  return (
    <div>
      {loadingScreen ? (
        <div className="flex justify-center items-center mt-10">
          Loading....
        </div>
      ) : (
        <div className="mt-0">
          <div className="flex justify-end mt-1 mr-5 items-center space-x-2">
            <button
              className="bg-teal-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                history.push("/schedule");
              }}
            >
              Schedule
            </button>
            <button
              className="bg-green-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                history.push("/upload-jobs");
              }}
            >
              Upload CSV
            </button>

            <button
              className="bg-blue-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                orgLocationIdValue === ""
                  ? alert("Select location id")
                  : customerList !== undefined && setAddRecord(true);
              }}
            >
              Add Record
            </button>
          </div>

          <div className="flex justify-between mx-5 items-center">
            <div>
              <div className="flex flex-row items-center justify-center">
                {/* <button
                  className="bg-blue-600 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center "
                  onClick={() => {
                    setOrgLocationIdView(!orgLocationIdView);
                  }}
                >
                  Select Location Id
                  <p className="font-medium">
                    {orgLocationIdValue !== "" && " - " + orgLocationIdValue}
                  </p>
                  {orgLocationIdView ? (
                    <IoMdArrowDropup />
                  ) : (
                    <IoMdArrowDropdown />
                  )}
                </button>

                {orgLocationIdValue !== "" && (
                  <MdOutlineClose
                    onClick={() => {
                      setOrgLocationIdValue("");
                      closeSelect();
                    }}
                    className="ml-2 text-xl cursor-pointer"
                  />
                )} */}

                <div className="flex items-center">
                  <List
                    data={orgLocationIdValueList}
                    handleInputChangeNewData={(item) => {
                      setOrgLocationIdValue(item);
                      setOrgLocationIdValueList({
                        name: "Location Id - " + item
                      });
                    }}
                    options={orgLocationId}
                  />
                  {orgLocationIdValue !== "" && (
                    <MdOutlineClose
                      onClick={() => {
                        setOrgLocationIdValue("");
                        setOrgLocationIdValueList({
                          name: "Select location Id"
                        });
                        closeSelect();
                      }}
                      className="ml-2 text-xl cursor-pointer"
                    />
                  )}
                </div>

                {/*  {orgLocationIdValue !== "" && selectedItems.length !== 0 && (
                  <button
                    className={`${deleteShow ? "bg-red-600" : "bg-blue-600"
                      } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      setDeleteAll(true);
                    }}
                  >
                    Delete All
                  </button>
                )}
                {orgLocationIdValue !== "" && (
                  <button
                    className={`${deleteShow ? "bg-red-600" : "bg-blue-600"
                      } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      deleteShow ? closeSelect() : setDeleteShow(true);
                    }}
                  >
                    {deleteShow ? "Cancel" : "Select Multiple"}
                  </button>
                )} */}
              </div>

              {/* {orgLocationIdView && (
                <div
                  className={`z-10 absolute bg-white  
               divide-gray-100 rounded-lg 
               shadow w-44`}
                >
                  <ul className="py-2 text-sm text-gray-700 cursor-pointer">
                    {orgLocationId?.map((item, index) => {
                      return (
                        <li key={index}>
                          <p
                            onClick={() => {
                              setOrgLocationIdValue(item);
                              setOrgLocationIdView(false);
                            }}
                            className="block px-4 py-2 
                        hover:bg-gray-100"
                          >
                            {item}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )} */}
            </div>

            <input
              type="text"
              placeholder="Search Record..."
              value={searchTerm}
              onChange={(event) =>
                handleSearch(event.target.value.toLowerCase())
              }
              className="border border-gray-200 p-3 h-10 
              rounded mb-3 focus:border-gray-300 outline-gray-300"
            />
          </div>
          {orgLocationIdValue === "" ? (
            <div className="flex justify-center items-center mt-10">
              Select Location Id
            </div>
          ) : loading ? (
            <div className="flex justify-center items-center mt-10">
              Loading....
            </div>
          ) : currentItems.length === 0 ? (
            <div className="flex justify-center items-center mt-10">
              {orgLocationIdValue} - Empty list
            </div>
          ) : (
            <div>
              <div className="mx-5 overflow-x-scroll">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        <p>ACTION</p>
                      </th>
                      {jobColumnsData.map((column) => (
                        <th
                          key={column.accessor}
                          style={{ border: "1px solid black", padding: "8px" }}
                          className="uppercase"
                        >
                          {column.Header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr
                        class="bg-white border-b
                        hover:bg-gray-50"
                        key={index}
                      >
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          <dix className={`flex flex-row ${"justify-between"}`}>
                            <div
                              className="flex flex-row justify-between
                                 items-center w-full"
                            >
                              <div
                                className="transititext-primary text-primary
                                      transition duration-150 ease-in-out 
                                      hover:text-primary-600 focus:text-primary-600 
                                      active:text-primary-700 cursor-pointer"
                                data-te-toggle="tooltip"
                                title="Edit"
                              >
                                <MdEdit
                                  onClick={() => {
                                    clickEdit(item);
                                  }}
                                  className="text-blue-600"
                                />
                              </div>

                              {deleteShow ? (
                                <div
                                  className="transititext-primary text-primary
                                 transition duration-150 ease-in-out 
                                 hover:text-primary-600 focus:text-primary-600 
                                 active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Select"
                                >
                                  <div class="flex items-center">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      class="w-4 h-4 text-blue-600 bg-gray-100
                                        border-gray-300 rounded"
                                      checked={selectedItems.some(
                                        (value) => value?.id === item.id
                                      )}
                                      onChange={(event) =>
                                        handleCheckboxChange(event, item)
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="transititext-primary text-primary
                                  transition duration-150 ease-in-out 
                                  hover:text-primary-600 focus:text-primary-600 
                                  active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Delete"
                                >
                                  <MdDelete
                                    className="text-red-600"
                                    onClick={() =>
                                      setOpen({
                                        condition: true,
                                        item: item
                                      })
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </dix>
                        </td>
                        {jobColumnsData.map((column) => (
                          <td
                            key={column.accessor}
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                          >
                            {column.accessor === "startDate" ? (
                              <p className="">
                                {moment(item[column.accessor]).format(
                                  "MM-DD-YYYY"
                                )}
                              </p>
                            ) : column.accessor === "endDate" ? (
                              <p className="w-24">
                                {moment(item[column.accessor]).format(
                                  "MM-DD-YYYY"
                                )}
                              </p>
                            ) : column.accessor === "startTime" ? (
                              <p className="">
                                {moment(item[column.accessor]).format(
                                  "hh:mm a"
                                )}
                              </p>
                            ) : column.accessor === "endTime" ? (
                              <p className="">
                                {moment(item[column.accessor]).format(
                                  "hh:mm a"
                                )}
                              </p>
                            ) : column.accessor === "createdAt" ? (
                              <p className="w-24">
                                {moment(item[column.accessor]).format(
                                  "MM-DD-YYYY hh:mm a"
                                )}
                              </p>
                            ) : (
                              <p style={{ padding: "9px" }}>
                                {item[column.accessor]}
                              </p>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="space-x-3 mr-5 flex justify-end my-5 items-center">
                <button
                  className="bg-green-600 my-5 text-white px-5 py-1 rounded-md"
                  onClick={() => downloadCSVFile(convertToCSV(downloadData))}
                >
                  Download
                </button>
                <div>
                  <label>Items per page:</label>
                  <select
                    value={itemsPerPage}
                    onChange={(event) => setItemsPerPage(event.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <button
                  onClick={() => handlePrevPage()}
                  className={`px-5 py-1 rounded-md ${
                    prePage !== null
                      ? "bg-blue-500 text-white"
                      : "bg-slate-100 text-black cursor-not-allowed"
                  }`}
                >
                  pre
                </button>
                <p>{currentPage}</p>
                <button
                  onClick={() => handleNextPage()}
                  className={`px-5 py-1 rounded-md ${
                    nextPage !== null
                      ? "bg-blue-500 text-white"
                      : "bg-slate-100 text-black cursor-not-allowed"
                  }`}
                >
                  next
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <Transition.Root show={open.condition} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setOpen({ condition: e, item: {} })}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete ?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteRecord(open.item)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen({ condition: false, item: {} })}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={deleteAll} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setDeleteAll(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete All Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete all record?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteMultipleRecord()}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteAll(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addRecord} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setAddRecord(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="flex min-h-full 
            items-end justify-center p-4 
            text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all mx-16 my-5 w-full"
                >
                  <div className="px-4 flex-1 bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-5">
                        <JobForm
                          jobNewData={jobNewData}
                          licenseTypeArr={licenseTypeArr}
                          setLicenseTypeArr={setLicenseTypeArr}
                          handleInputChangeNewData={handleInputChangeNewData}
                          customerList={customerList}
                          managerList={managerList}
                          type={type}
                          edit={edit}
                          orgLocationIdValue={orgLocationIdValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="bg-white px-4 py-6 sm:flex
                   sm:flex-row-reverse sm:px-6"
                  >
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md
                       bg-green-600 px-3 py-2 text-sm font-semibold text-white
                        shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() =>
                        edit !== undefined ? updateCustomer(edit) : postJob()
                      }
                    >
                      {edit !== undefined
                        ? "Update Schedule"
                        : "Create Schedule"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center
                       rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setAddRecord(false);
                        setEdit(undefined);
                        setLicenseTypeArr([]);
                        setJobNewData({
                          shiftTitle: "",
                          startDate: new Date(),
                          endDate: new Date(),
                          startTime: new Date(),
                          endTime: new Date(),
                          break: "No Break",
                          customerName: "Select Customer",
                          customerId: "",
                          unit: "",
                          floor: "",
                          fullAddress: "",
                          latitude: "",
                          longitude: "",
                          notes: "",
                          jobType: type,
                          jobTiming: "",
                          customerVisibility: false,
                          jobPostingTableFacilityTableId: "",
                          jobPostingTableFacilityTableName: ""
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default JobTableView;
