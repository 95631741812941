import React, { Fragment, useEffect, useState } from 'react'
import { getOrganizationDetails, updateDetails } from '../../api_url/organizationTable';
import { useAuth } from '../../context/AuthContext';
import LocationForm from './LocationForm';
import { Dialog, Transition } from "@headlessui/react";

function LocationDetails() {

  const { authToken, userId } = useAuth();
  const [organization, setOrganization] = useState(undefined);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [addRecord, setAddRecord] = useState(false);
  const [address, setAddress] = useState(false);
  const [newLocation, setNewLocation] = useState(
    {
      "location_id": "",
      "location_city": "",
      "location_state": "",
      "location_country": "",
      "location_zipCode": 0,
      "location_fullAddress": "",
      "latitude": "",
      "longitude": ""
    }
  );

  const getOrgLocationList = async () => {
    await getOrganizationDetails(authToken, userId)
      .then((res) => {
        if (res) {
          setOrganization(res);
          setLoadingScreen(false);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  useEffect(() => {
    getOrgLocationList();
  }, [authToken, userId]);

  const handleInputChangeNewData = (event) => {
    setNewLocation((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const openMap = (from) => {
    const url = `https://maps.google.com/?q=${from}`;
    // const url = `https://maps.google.com/?saddr=${from},&daddr=${to}}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  const validateFields = (data) => {
    let isValid = true;
    const emptyFields = [];

    const location_id = data?.location_id;
    const location_fullAddress = data?.location_fullAddress;

    if (
      location_fullAddress === "" ||
      location_id === ""
    ) {
      isValid = false;

      if (location_fullAddress === "") {
        emptyFields.push("Address is missing");
      }
      if (location_id === "") {
        emptyFields.push("Location id is missing");
      }
    }

    return {
      isValid,
      emptyFields,
    };
  };

  const updateLocation = async () => {
    const validationResult = validateFields(newLocation);
    if (validationResult.isValid) {
      try {
        await updateDetails(authToken, userId, {
          locations: [
            ...organization?.locations,
            newLocation
          ]
        }).then(res => {
          console.log(res)
          alert("Location added successfully.");
          setNewLocation({
            "location_id": "",
            "location_city": "",
            "location_state": "",
            "location_country": "",
            "location_zipCode": 0,
            "location_fullAddress": "",
            "latitude": "",
            "longitude": ""
          })
          setAddRecord(false)
          getOrgLocationList();
        }).catch(e => {
          console.log(e)
        })
      } catch (error) {
        console.log("data save error:", error);
      }
    } else {
      alert(
        validationResult.emptyFields +
        (validationResult.emptyFields.length > 1
          ? validationResult.emptyFields.length ===
            validationResult.emptyFields.length - 1
            ? ","
            : ""
          : "") +
        ". Please enter a value."
      );
    }
  };

  return (
    <div className=' '>
      {loadingScreen
        ?
        <div>
          Loading...
        </div>
        :
        <div class="flex items-center min-h-screen">
          <div class="container ml-auto mr-auto flex flex-wrap items-start">
            <div class="w-full mb-2 lg:-mt-20 pl-5 lg:pl-2 flex-col">
              <div className="flex justify-start mt-1 mr-5 items-center space-x-2">
                <h1 class="text-3xl lg:text-4xl text-gray-700 font-medium">
                  {organization?.organizationName}
                </h1>
                <button
                  className="bg-blue-600 my-3 text-white px-5 py-1 rounded-md"
                  onClick={() => {
                    setAddRecord(true)
                  }}
                >
                  Add Location
                </button>
              </div>
              <div>Phone Number = {organization?.phoneNumber}</div>
              <div>Main Branch = {organization?.mainBranch}</div>
              <div>Address = {organization?.fullAddress}</div>
              <div>Locations :</div>
            </div>
            {
              organization?.locations?.map((item, index) => {
                // let add = await handleGeocode(item?.location_fullAddress)
                return (
                  <div
                    key={index}
                    class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2
                    cursor-pointer"
                    onClick={() => openMap(item?.location_fullAddress)}
                  >
                    <div class="bg-blue-100 rounded-lg m-h-64 p-2
                     transform hover:translate-y-2 hover:shadow-xl 
                     transition duration-300">
                      <figure class="mb-2">
                        {/* <img
                          src={map}
                          alt=""
                          class="h-64 ml-auto mr-auto"
                        /> */}
                        <iframe
                          width="100%"
                          height="250"
                          loading="lazy"
                          src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d10!2d${item?.longitude}!3d${item?.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1645431513458`}
                          title="Google Maps"
                        ></iframe>
                      </figure>
                      <div class="rounded-lg p-4 bg-blue-700 flex flex-col">
                        <div>
                          <h1 class="text-white text-xl font-bold leading-none">
                            {item?.location_id}
                          </h1>
                        </div>
                        <div class="flex items-center mt-2">
                          <div class="text-sm text-white font-normal">
                            {item?.location_fullAddress}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  // <div key={index}>
                  //   <div>City = {item?.location_city}</div>
                  //   <div>Location Id = {item?.location_id}</div>
                  //   <div>State = {item?.location_state}</div>
                  //   <div>ZipCode = {item?.location_zipCode}</div>
                  //   <div>Full Address = {item?.location_fullAddress}</div>
                  // </div>
                )
              })
            }
          </div>
        </div>
      }
      <Transition.Root show={addRecord} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setAddRecord(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full 
            items-end justify-center p-4 
            text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all mx-16 my-5 w-full">
                  <div className="px-4 flex-1 bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-5">
                        <LocationForm
                          newLocation={newLocation}
                          handleInputChangeNewData={handleInputChangeNewData}
                          address={address}
                          setAddress={setAddress}
                        />
                      </div>
                    </div>
                  </div>
                  {!address &&
                    <div className="bg-white px-4 pb-6 sm:flex
                   sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md
                       bg-green-600 px-3 py-2 text-sm font-semibold text-white
                        shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => updateLocation()}
                      >
                        {
                          "Create Location"
                        }
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center
                       rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setAddRecord(false)
                        }}
                      >
                        Cancel
                      </button>
                    </div>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default LocationDetails