import { Fragment, useEffect, useState } from "react";
import { MdEdit, MdOutlineClose, MdDelete } from "react-icons/md";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../../context/AuthContext";
import { getOrganizationDetails } from "../../api_url/organizationTable";
import {
  createCustomer,
  deleteCustomer,
  deleteCustomerMulti,
  getCustomerTableList,
  updateDetails
} from "../../api_url/customerTable";
import CustomerForm from "./CustomerForm";
import List from "../../components/ListLocation";

function CustomerTable() {
  const history = useHistory();
  const { authToken, userId } = useAuth();

  const [open, setOpen] = useState({
    condition: false,
    item: {}
  });

  const [deleteAll, setDeleteAll] = useState(false);
  const [address, setAddress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orgLocation, setOrgLocation] = useState("");
  const [orgLocationId, setOrgLocationId] = useState([]);
  const [orgLocationIdView, setOrgLocationIdView] = useState(false);
  const [orgLocationIdValue, setOrgLocationIdValue] = useState("");
  const [orgLocationIdValueList, setOrgLocationIdValueList] = useState({
    name: "Select location Id"
  });

  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(undefined);
  const [editedData, setEditedData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const format = (value) => {
    const inputPhoneNumber = value.replace(/\D/g, "");
    const limitedPhoneNumber = inputPhoneNumber.slice(0, 10);
    const formattedPhoneNumber =
      limitedPhoneNumber.length === 10
        ? limitedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : limitedPhoneNumber;
    return formattedPhoneNumber;
  };

  const [columnsData, setColumnsData] = useState([
    {
      Header: "location_id",
      accessor: "location_id"
    },
    {
      Header: "organization",
      accessor: "organization"
    },
    {
      Header: "firstName",
      accessor: "firstName"
    },
    {
      Header: "lastName",
      accessor: "lastName"
    },
    {
      Header: "fullAddress",
      accessor: "fullAddress"
    },
    {
      Header: "city",
      accessor: "city"
    },
    {
      Header: "state",
      accessor: "state"
    },
    {
      Header: "zipCode",
      accessor: "zipCode"
    },

    {
      Header: "gender",
      accessor: "gender"
    },
    {
      Header: "Primary Number",
      accessor: "phoneOne"
    },
    {
      Header: "Secondary Number",
      accessor: "phoneTwo"
    },
    {
      Header: "email",
      accessor: "email"
    },
    {
      Header: "active_status",
      accessor: "active"
    }
  ]);
  const [newData, setNewData] = useState({
    firstName: "", //
    lastName: "", //
    fullAddress: "", //
    latitude: "", //
    longitude: "", //
    city: "", //
    state: "", //
    zipCode: "", //
    gender: "", //
    patientOne: "", //
    patientTwo: "", //
    email: "", //
    organization: "",
    location_id: "",
    active: true
  });

  const getOrgCustomer = async () => {
    await getOrganizationDetails(authToken, userId)
      .then((res) => {
        let arr = [];
        // console.log(res)
        if (res) {
          setOrgLocation(res.organizationName);
          res?.locations.map((i) =>
            arr.push({
              name: i.location_id
            })
          );
          if (res?.locations.length === arr.length) {
            // console.log(arr);
            setOrgLocationId(arr);
            setLoadingScreen(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCustomer = async (location, organization) => {
    setLoading(true);
    setEditedData([]);
    setDownloadData([]);
    try {
      const nurseList = await getCustomerTableList(
        authToken,
        location,
        organization
      );

      const list = nurseList.sort((a, b) =>
        a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
      );
      // console.log(list);
      let array = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        let obj = {
          firstName: item.firstName,
          lastName: item.lastName,
          fullAddress: item.fullAddress,
          city: item.city,
          state: item.state,
          zipCode: item.zipCode,
          gender: item.gender,
          phoneOne: item.phoneOne,
          phoneTwo: item.phoneTwo,
          email: item.email
        };
        array.push(obj);
      }
      // Assuming the array of objects is stored in a variable called 'data'
      // const duplicates = list.filter((item, index, a) => {
      //   return a.some((element, i) => {
      //     // Avoid comparing the same element or elements after the current index
      //     if (i <= index) return false;

      //     // Compare the desired fields for duplication
      //     return (
      //       element.patient_first_name === item.patient_first_name &&
      //       element.patient_last_name === item.patient_last_name &&
      //       element.patient_address_line_1 === item.patient_address_line_1 &&
      //       element.patient_city === item.patient_city &&
      //       element.patient_state === item.patient_state &&
      //       element.patient_zip_code === item.patient_zip_code
      //     );
      //   });
      // });

      // console.log(duplicates);

      if (list.length === array.length) {
        setEditedData(list);
        setDownloadData(array);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setEditedData([]);
      setDownloadData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrgCustomer();
  }, [authToken, userId]);

  useEffect(() => {
    if (orgLocationIdValue !== "" && orgLocation !== "") {
      getCustomer(orgLocationIdValue, orgLocation);
    }
  }, [orgLocationIdValue]);

  const handleInputChangeNewData = (event) => {
    setNewData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const validateFields = (data) => {
    let isValid = true;
    const emptyFields = [];

    const firstName = data?.firstName;
    const lastName = data?.lastName;
    const address = data?.fullAddress;
    const gender = data?.gender;
    const phone = data?.patientOne;

    if (
      firstName === "" ||
      lastName === "" ||
      address === "" ||
      gender === "" ||
      phone === ""
    ) {
      isValid = false;

      if (firstName === "") {
        emptyFields.push("First Name is missing");
      }
      if (lastName === "") {
        emptyFields.push("Last Name is missing");
      }
      if (address === "") {
        emptyFields.push("Address is missing");
      }
      if (gender === "") {
        emptyFields.push("Gender is missing");
      }
      if (phone === "") {
        emptyFields.push("Phone is missing");
      }
    }

    return {
      isValid,
      emptyFields
    };
  };

  const dataCheck = !editedData.some((serverItem) => {
    return (
      serverItem.firstName === newData.firstName &&
      serverItem.lastName === newData.lastName &&
      serverItem.phoneOne === newData.patientOne
    );
  });

  const dataCheckEdit = !editedData.some((serverItem) => {
    return (
      serverItem.firstName === newData.firstName &&
      serverItem.lastName === newData.lastName &&
      serverItem.phoneOne === newData.patientOne
    );
  });

  const postCustomer = async () => {
    const validationResult = validateFields(newData);

    if (validationResult.isValid) {
      if (dataCheck) {
        try {
          await createCustomer(authToken, {
            firstName: newData.firstName,
            lastName: newData.lastName,
            fullAddress: newData.fullAddress,
            latitude: newData.latitude?.toString(),
            longitude: newData.longitude?.toString(),
            cityId: "",
            city: newData.city,
            state: newData.state,
            zipCode: newData.zipCode,
            gender: newData.gender,
            phoneOne: newData.patientOne,
            phoneTwo: newData.patientTwo,
            email: newData.email,
            active: true,
            organization: orgLocation,
            location_id: orgLocationIdValue
          })
            .then((res) => {
              alert(
                res.firstName +
                  " " +
                  res.lastName +
                  " information added successfully."
              );
              setNewData({
                firstName: "",
                lastName: "",
                fullAddress: "",
                city: "",
                state: "",
                zipCode: "",
                gender: "",
                patientOne: "",
                patientTwo: "",
                email: "",
                organization: "",
                location_id: "",
                active: true
              });
              setAdd(false);
              getCustomer(orgLocationIdValue, orgLocation);
            })
            .catch((e) => console.log(e));
        } catch (error) {
          console.log("data save error:", error);
        }
      } else {
        alert(newData.firstName + " record already stored in database!");
      }
    } else {
      alert(
        validationResult.emptyFields +
          (validationResult.emptyFields.length > 1
            ? validationResult.emptyFields.length ===
              validationResult.emptyFields.length - 1
              ? ","
              : ""
            : "") +
          ". Please enter a value."
      );
    }
  };

  const clickEdit = (item) => {
    setNewData({
      ...newData,
      firstName: item?.firstName,
      lastName: item?.lastName,
      fullAddress: item?.fullAddress,
      latitude: item?.latitude,
      longitude: item?.longitude,
      city: item?.city,
      state: item?.state,
      zipCode: item?.zipCode,
      gender: item?.gender,
      patientOne: item?.phoneOne,
      patientTwo: item?.phoneTwo,
      email: item?.email,
      active: item?.active
    });
    setAdd(true);
    setEdit(item);
  };

  const updateCustomer = async (item) => {
    const validationResult = validateFields(edit);

    if (validationResult.isValid) {
      // if (dataCheckEdit) {
      // console.log(edit)
      try {
        const res = await updateDetails(authToken, edit?.id, {
          firstName: newData.firstName,
          lastName: newData.lastName,
          fullAddress: newData.fullAddress,
          latitude: newData.latitude?.toString(),
          longitude: newData.longitude?.toString(),
          cityId: "",
          city: newData.city,
          state: newData.state,
          zipCode: newData.zipCode,
          gender: newData.gender,
          phoneOne: newData.patientOne,
          phoneTwo: newData.patientTwo,
          email: newData.email,
          active: newData?.active
        });
        alert(
          res.firstName + " " + res.lastName + " record updated successfully."
        );
        setAdd(false);
        setEdit(undefined);
        getCustomer(orgLocationIdValue, orgLocation);
      } catch (error) {
        console.log("data save error:", error);
      }
    }
    //    else {
    //     alert(edit.firstName + " record already stored in database!");
    //   }
    // }
    else {
      alert(
        validationResult.emptyFields +
          (validationResult.emptyFields.length > 1
            ? validationResult.emptyFields.length ===
              validationResult.emptyFields.length - 1
              ? ","
              : ""
            : "") +
          ". Please enter a value."
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(editedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(editedData);

  const handleSearch = (value) => {
    setSearchTerm(value);

    const filteredData = editedData.filter((item) => {
      for (const key in item) {
        const value = item[key];
        if (value && value.toString().toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
      return false;
    });

    setSearchResults(filteredData);
  };

  const currentItems =
    searchTerm === ""
      ? editedData.slice(indexOfFirstItem, indexOfLastItem)
      : searchResults.slice(indexOfFirstItem, indexOfLastItem);

  function convertToCSV(jsonData) {
    const separator = ",";
    const keys = Object.keys(jsonData[0]);
    const csvContent = [
      keys.join(separator),
      ...jsonData.map((item) => {
        // Extract the 'fullAddress' property
        const { fullAddress, ...rest } = item;

        // Join the values of the other properties with the 'FullAddress' value
        const values = keys.map((key) =>
          key === "fullAddress" ? `"${fullAddress}"` : rest[key]
        );

        return values.join(separator);
      })
    ].join("\n");

    return csvContent;
  }

  const downloadCSVFile = (data) => {
    let current = moment().format("MM-DD-YYYY");
    // Create a Blob object for the CSV data
    const blob = new Blob([data], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `CustomerDetails-${current}.csv`;

    // Trigger the download
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  const deleteRecord = async (item) => {
    // console.log("Delete", item);
    await deleteCustomer(authToken, item?.id)
      .then((res) => {
        setSearchTerm("");
        setOpen({ condition: false, item: {} });
        getCustomer(orgLocationIdValue, orgLocation);
      })
      .catch((e) => console.log(e));
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
  };

  const closeSelect = () => {
    setDeleteShow(false);
    setSelectedItems([]);
  };

  const deleteMultipleRecord = async () => {
    await deleteCustomerMulti(
      authToken,
      selectedItems.map((i) => i?.id)
    )
      .then((res) => {
        getCustomer(orgLocationIdValue, orgLocation);
        setSelectedItems([]);
        setDeleteShow(false);
        setDeleteAll(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      {loadingScreen ? (
        <div className="flex justify-center items-center mt-10">
          Loading....
        </div>
      ) : (
        <div className="mt-0">
          <div className="flex justify-end mt-1 mr-5 items-center space-x-2">
            <button
              className="bg-green-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                history.push("/upload-patient");
              }}
            >
              Upload CSV
            </button>

            <button
              className="bg-blue-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                orgLocationIdValue === ""
                  ? alert("Select location id")
                  : setAdd(true);
              }}
            >
              Add Record
            </button>
          </div>
          <div className="flex justify-between mx-5 items-center">
            <div>
              <div className="flex flex-row items-center justify-center">
                {/* <button
                  className="bg-blue-600 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center "
                  onClick={() => {
                    setOrgLocationIdView(!orgLocationIdView);
                  }}
                >
                  Select Location Id
                  <p className="font-medium">
                    {orgLocationIdValue !== "" && " - " + orgLocationIdValue}
                  </p>
                  {orgLocationIdView ? (
                    <IoMdArrowDropup />
                  ) : (
                    <IoMdArrowDropdown />
                  )}
                </button> 

                {orgLocationIdValue !== "" && (
                  <MdOutlineClose
                    onClick={() => {
                      setOrgLocationIdValue("");
                      closeSelect();
                    }}
                    className="ml-2 text-xl cursor-pointer"
                  />
                )}*/}
                <div className="flex items-center">
                  <List
                    data={orgLocationIdValueList}
                    handleInputChangeNewData={(item) => {
                      setOrgLocationIdValue(item);
                      setOrgLocationIdValueList({
                        name: "Location Id - " + item
                      });
                    }}
                    options={orgLocationId}
                  />
                  {orgLocationIdValue !== "" && (
                    <MdOutlineClose
                      onClick={() => {
                        setOrgLocationIdValue("");
                        setOrgLocationIdValueList({
                          name: "Select location Id"
                        });
                        closeSelect();
                      }}
                      className="ml-2 text-xl cursor-pointer"
                    />
                  )}
                </div>

                {orgLocationIdValue !== "" && selectedItems.length !== 0 && (
                  <button
                    className={`${
                      deleteShow ? "bg-red-600" : "bg-blue-600"
                    } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      setDeleteAll(true);
                    }}
                  >
                    Delete All
                  </button>
                )}
                {/* {orgLocationIdValue !== "" && (
                  <button
                    className={`${
                      deleteShow ? "bg-red-600" : "bg-blue-600"
                    } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      deleteShow ? closeSelect() : setDeleteShow(true);
                    }}
                  >
                    {deleteShow ? "Cancel" : "Select Multiple"}
                  </button>
                )} */}
              </div>

              {/* {orgLocationIdView && (
                <div
                  className={`z-10 absolute bg-white  
               divide-gray-100 rounded-lg 
               shadow w-44`}
                >
                  <ul className="py-2 text-sm text-gray-700 cursor-pointer">
                    {orgLocationId?.map((item, index) => {
                      return (
                        <li key={index}>
                          <p
                            onClick={() => {
                              setOrgLocationIdValue(item);
                              setOrgLocationIdView(false);
                            }}
                            className="block px-4 py-2 
                            hover:bg-gray-100"
                          >
                            {item}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )} */}
            </div>

            <input
              type="text"
              placeholder="Search Patient Record..."
              value={searchTerm}
              onChange={(event) =>
                handleSearch(event.target.value.toLowerCase())
              }
              className="border border-gray-200 p-3 h-10 
              rounded mb-3 focus:border-gray-300 outline-gray-300"
            />
          </div>
          {orgLocationIdValue === "" ? (
            <div className="flex justify-center items-center mt-10">
              Select Location Id
            </div>
          ) : loading ? (
            <div className="flex justify-center items-center mt-10">
              Loading....
            </div>
          ) : currentItems.length === 0 ? (
            <div className="flex justify-center items-center mt-10">
              {orgLocationIdValue} - Empty list
            </div>
          ) : (
            <div>
              <div style={{ overflowX: "auto" }} className="mx-5">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        <p>ACTION</p>
                      </th>
                      {columnsData.map((column) => (
                        <th
                          key={column.accessor}
                          style={{ border: "1px solid black", padding: "8px" }}
                          className="uppercase"
                        >
                          {column.Header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr
                        class="bg-white border-b
                        hover:bg-gray-50"
                        key={index}
                      >
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          <dix className={`flex flex-row ${"justify-center"}`}>
                            <div
                              className="flex flex-row justify-center
                                 items-center w-full"
                            >
                              <div
                                className="transititext-primary text-primary
                                      transition duration-150 ease-in-out 
                                      hover:text-primary-600 focus:text-primary-600 
                                      active:text-primary-700 cursor-pointer"
                                data-te-toggle="tooltip"
                                title="Edit"
                              >
                                <MdEdit
                                  onClick={() => {
                                    clickEdit(item);
                                  }}
                                  className="text-blue-600"
                                />
                              </div>

                              {/* {deleteShow ? (
                                <div
                                  className="transititext-primary text-primary
                                 transition duration-150 ease-in-out 
                                 hover:text-primary-600 focus:text-primary-600 
                                 active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Select"
                                >
                                  <div class="flex items-center">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      class="w-4 h-4 text-blue-600 bg-gray-100
                                        border-gray-300 rounded"
                                      checked={selectedItems.some(
                                        (value) => value?.id === item.id
                                      )}
                                      onChange={(event) =>
                                        handleCheckboxChange(event, item)
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="transititext-primary text-primary
                                  transition duration-150 ease-in-out 
                                  hover:text-primary-600 focus:text-primary-600 
                                  active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Delete"
                                >
                                  <MdDelete
                                    className="text-red-600"
                                    onClick={() =>
                                      setOpen({
                                        condition: true,
                                        item: item
                                      })
                                    }
                                  />
                                </div>
                              )} */}
                            </div>
                          </dix>
                        </td>
                        {columnsData.map((column) => (
                          <td
                            key={column.accessor}
                            style={{
                              border: "1px solid black",
                              padding: "8px"
                            }}
                          >
                            {column.accessor === "active" ? (
                              <p className="w-24">
                                {item[column.accessor] ? "Yes" : "No"}
                              </p>
                            ) : column.accessor === "phoneOne" ? (
                              <p className="w-24">
                                {format(item[column.accessor])}
                              </p>
                            ) : column.accessor === "phoneTwo" ? (
                              <p className="w-24">
                                {format(item[column.accessor])}
                              </p>
                            ) : (
                              <p style={{ padding: "9px" }}>
                                {item[column.accessor]}
                              </p>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="space-x-3 mr-5 flex justify-end my-5 items-center">
                <button
                  className="bg-green-600 my-5 text-white px-5 py-1 rounded-md"
                  onClick={() => downloadCSVFile(convertToCSV(downloadData))}
                >
                  Download
                </button>
                <div>
                  <label>Items per page:</label>
                  <select
                    value={itemsPerPage}
                    onChange={(event) => setItemsPerPage(event.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <button
                  onClick={() => handlePrevPage()}
                  className={`px-5 py-1 rounded-md ${
                    currentPage > 1
                      ? "bg-blue-500 text-white"
                      : "bg-slate-100 text-black cursor-not-allowed"
                  }`}
                >
                  pre
                </button>
                <p>{currentPage}</p>
                <button
                  onClick={() => handleNextPage()}
                  className={`px-5 py-1 rounded-md ${
                    currentPage < pageNumbers.length
                      ? "bg-blue-500 text-white"
                      : "bg-slate-100 text-black cursor-not-allowed"
                  }`}
                >
                  next
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <Transition.Root show={open.condition} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setOpen({ condition: e, item: {} })}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete ?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteRecord(open.item)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen({ condition: false, item: {} })}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={deleteAll} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setDeleteAll(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete All Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete all record?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteMultipleRecord()}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteAll(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={add} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(e) => setAdd(e)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="flex min-h-full 
            items-end justify-center p-4 
            text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all mx-16 my-5 w-full"
                >
                  <div className="px-4 flex-1 bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-5">
                        <CustomerForm
                          jobNewData={newData}
                          handleInputChangeNewData={handleInputChangeNewData}
                          edit={edit}
                          orgLocationIdValue={orgLocationIdValue}
                          address={address}
                          setAddress={setAddress}
                        />
                      </div>
                    </div>
                  </div>
                  {!address && (
                    <div
                      className="bg-white px-4 py-6 sm:flex
                   sm:flex-row-reverse sm:px-6"
                    >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md
                       bg-green-600 px-3 py-2 text-sm font-semibold text-white
                        shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() =>
                          edit !== undefined
                            ? updateCustomer(edit)
                            : postCustomer()
                        }
                      >
                        {edit !== undefined
                          ? "Update Customer"
                          : "Create Customer"}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center
                       rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setAdd(false);
                          setEdit(undefined);
                          setNewData({
                            firstName: "",
                            lastName: "",
                            fullAddress: "",
                            city: "",
                            state: "",
                            zipCode: "",
                            gender: "",
                            patientOne: "",
                            patientTwo: "",
                            email: "",
                            organization: "",
                            location_id: "",
                            active: true
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CustomerTable;
